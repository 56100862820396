import React, { FC, useEffect, useState } from 'react';
import theme from './approvalTable.scss';
import { IntlShape, useIntl } from 'react-intl';
import messages from '../messages/approval.messages';
import { useDispatch, useSelector } from 'react-redux';
import { timestampToMomentDate } from '../../../utils/date.time.utils';
import { checkApprovals, editExistingApproval } from '../../../actions/approval.actions';
import { Badge } from 'antd';
import { ElementNames } from './ElementNames.component';
import { TApprovalTableData } from './approvalsTable.types';
import icApprovalEdit from '../../../resources/icons/ic-approval-edit.svg';
import icApprovalCopy from '../../../resources/icons/ic-copy.svg';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TColumn, TTableData } from '@/modules/UIKit/components/Table/TableUIKit.types';
import { TableUIKit } from '@/modules/UIKit/components/Table/TableUIKit.component';
import { sortByAlpha, sortByNumber } from '@/utils/sortByAlpha';
import { ApprovalSelectors } from '@/selectors/approval.selectors';
import { Icon } from '@/modules/UIKit/components/Icon/Icon.component';
import { NodeId } from '@/serverapi/api';

enum statusColor {
    IN_PROCESS = '#8F8F8F',
    APPROVED = '#00A455',
    NOT_APPROVED = '#FF3733',
    CANCELLED = '#676767',
    DRAFT = '#323232',
}

type TApprovalTableProps = {
    approvalTableData: TApprovalTableData[];
};

export const ApprovalTable: FC<TApprovalTableProps> = ({ approvalTableData }): JSX.Element => {
    const intl: IntlShape = useIntl();
    const dispatch = useDispatch();
    const [needToRender, setNeedToRender] = useState<boolean>(false);
    const getCheckedApprovalIds = useSelector(ApprovalSelectors.getCheckedApprovalIds);

    useEffect(() => {
        if (needToRender) setNeedToRender(false);
    }, [needToRender, approvalTableData]);

    const handleCopy = (id: NodeId) => {
        dispatch(
            openDialog(DialogType.SELECT_TREE_ITEM_APPROVAL_DIALOG, {
                copiedApprovalId: id,
            }),
        );
    };

    const columns: TColumn[] = [
        {
            dataKey: 'check',
            title: '',
            withoutSorter: true,
            width: 40,
        },
        {
            dataKey: 'name',
            title: intl.formatMessage(messages.name),
            sortFunction: (a: TTableData, b: TTableData) => sortByAlpha(a.name as string, b.name as string),
            defaultSort: 'ASC',
        },
        {
            dataKey: 'description',
            title: intl.formatMessage(messages.description),
            sortFunction: (a: TTableData, b: TTableData) =>
                sortByAlpha(a.description as string, b.description as string),
        },
        {
            dataKey: 'createdBy',
            title: intl.formatMessage(messages.createdBy),
            sortFunction: (a: TTableData, b: TTableData) => sortByAlpha(a.createdBy as string, b.createdBy as string),
        },
        {
            dataKey: 'createdAtFormatted',
            title: intl.formatMessage(messages.createdAt),
            sortFunction: (a: TTableData, b: TTableData) => sortByNumber(a.createdAt as number, b.createdAt as number),
        },
        {
            dataKey: 'statusFormated',
            title: intl.formatMessage(messages.status),
            sortFunction: (a: TTableData, b: TTableData) => sortByAlpha(a.status as string, b.status as string),
        },
        {
            dataKey: 'elements',
            title: intl.formatMessage(messages.elements),
            withoutSorter: true,
        },
        {
            dataKey: 'actions',
            title: '',
            withoutSorter: true,
        },
    ];
    const renderRowBtns = (item) => {
        return (
            <Badge
                className={theme.badgeStatus}
                count={intl.formatMessage(messages[item])}
                style={{ backgroundColor: statusColor[item] }}
            />
        );
    };
    const handleChange = (nodeIds: NodeId[]) => {
        dispatch(checkApprovals(nodeIds));
    };

    const handleCheckApprovals = (rows) => {
        const approvalIds: NodeId[] = rows.map((row) => row.id);

        handleChange(approvalIds);
    };

    const hadnleRowDoubleClick = ({ rowData }) => {
        const { id } = rowData;

        dispatch(editExistingApproval({ approvalId: id }));
    };

    const tableData: TTableData[] = approvalTableData.map((item) => ({
        checked: !!getCheckedApprovalIds.find((checkedApproval) => checkedApproval.id === item.id.id),
        id: item.id,
        name: item.name,
        description: item.description || '',
        createdBy: item.createdBy || '',
        createdAt: item.createdAt,
        createdAtFormatted: timestampToMomentDate(item.createdAt || -1)?.format(
            `DD.MM.YYYY ${intl.formatMessage(messages.at)} HH:mm`,
        ),
        status: item.status,
        statusFormated: renderRowBtns(item.status),
        elements: <ElementNames setNeedToRender={setNeedToRender} elementNames={item.elementNames as string[]} />,
        actions: (
            <div className={theme.actionBtnsContainer}>
                <span
                    data-test="approval_edit_btn"
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(editExistingApproval({ approvalId: item.id }));
                    }}
                >
                    <Icon spriteSymbol={icApprovalEdit} />
                </span>
                <span
                    onClick={(e) => {
                        e.stopPropagation();
                        handleCopy(item.id);
                    }}
                >
                    <Icon spriteSymbol={icApprovalCopy} />
                </span>
            </div>
        ),
    }));

    return (
        <TableUIKit
            columns={columns}
            tableData={tableData}
            withCkeckBox
            checkRows={handleCheckApprovals}
            hadnleRowDoubleClick={hadnleRowDoubleClick}
        />
    );
};
