import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button, Checkbox, Form, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { closeDialog, openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import scriptExecuteMessages from '@/modules/MainMenu/components/ScriptExecuteDialog/ScriptExecuteDialog.messages';
import theme from './ScheduleScriptDialog.scss';
import messages from './ScheduleScriptDialog.messages';
import { selectScriptToScheduleDialogInit } from '@/actions/scriptSelectDialog.actions';
import { AttributeValueQuery, SchedulerTimeSettings, ScriptParameter } from '@/serverapi/api';
import { TRootState } from '@/reducers/root.reducer.types';
import {
    scriptExecuteDialogAddNodeId,
    scriptExecuteDialogDeleteNodeId,
    scriptExecuteDialogDeleteParamFiles,
} from '@/actions/scriptExecuteDialog.actions';
import { ScriptExecuteForm } from '@/modules/MainMenu/components/ScriptExecuteDialog/ScriptExecuteForm.component';
import { FileUploadStatus, ParameterType } from '@/reducers/scriptExecuteDialog.reducer.types';
import dayjs from 'dayjs';
import { scriptEditScheduleSubmit, scriptSchedule } from '@/actions/entities/script.actions';
import { DatetimeSchedulerSettings } from '@/modules/DatetimeSchedulerSettings/DatetimeSchedulerSettings.component';
import { SchedulerPeriodEnum } from '@/modules/DatetimeSchedulerSettings/DatetimeSchedulerSettings.types';
import { momentDateToTimestamp } from '@/utils/date.time.utils';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { TScheduleScriptDialogProps } from './ScheduleScriptDialog.types';
import { getOpenScriptSchedulerData } from '@/selectors/openScriptSchedulerData.selectors';
import { clearOpenScriptSchedulerData } from '@/actions/openScriptSchedulerData.actions';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

export const ScheduleScriptDialog = (props: TScheduleScriptDialogProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [form] = Form.useForm();
    const [timeSettings, setTimeSettings] = useState<SchedulerTimeSettings>(
        props.schedulerTimeSettings || {
            schedulerPeriod: SchedulerPeriodEnum.SINGLE_CASE,
            startTime: momentDateToTimestamp(dayjs())!,
        },
    );
    const [disable, setDisable] = useState<boolean>(props.disable !== undefined ? props.disable : false);
    const [requiredFilesNotLoaded, setRequiredFilesNotLoaded] = useState<string[]>([]);
    const [requiredNodesNotSelected, setRequiredNodesNotSelected] = useState<string[]>([]);

    const scriptSchedulerData = useSelector(getOpenScriptSchedulerData);

    const fileParams = useSelector((state: TRootState) => state.scriptExecuteDialog.fileParams);
    const nodeParams = useSelector((state: TRootState) => state.scriptExecuteDialog.nodeParams);
    const [isScriptRequiredEnabled, setIsScriptRequiredEnabled] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            dispatch(clearOpenScriptSchedulerData());
        };
    }, []);

    const hasSchedulerRepeatError =
        timeSettings.repeatEverySeconds && timeSettings.stopRepeatAfterSeconds
            ? timeSettings.repeatEverySeconds > timeSettings.stopRepeatAfterSeconds
            : false;

    const getRequiredParamsByType = (type: ParameterType): ScriptParameter[] => {
        return Object.values(scriptSchedulerData.parsedParams || []).filter(
            (p) => p.required && p.paramType === ParameterType[type],
        );
    };

    const onClose = () => {
        dispatch(closeDialog(DialogType.SCHEDULE_SCRIPT_DIALOG));
        if (fileParams && scriptSchedulerData.script) {
            dispatch(
                scriptExecuteDialogDeleteParamFiles(
                    scriptSchedulerData.script.nodeId.serverId,
                    Object.values(fileParams)
                        .filter((fileParam) => fileParam.uploadedFileName)
                        .map((fileParam) => fileParam.uploadedFileName!),
                ),
            );
        }
    };
    const onSubmit = () => {
        const requiredFileParams = getRequiredParamsByType(ParameterType.FILE);
        const requiredNodeParams = getRequiredParamsByType(ParameterType.NODE);

        requiredFileParams.forEach((param) => {
            const fileParam = fileParams[param.name || ''];
            if (!fileParam || fileParam.uploadStatus !== FileUploadStatus.DONE)
                setRequiredFilesNotLoaded([...requiredFilesNotLoaded, param.name!]);
        });

        requiredNodeParams.forEach((param) => {
            if (!nodeParams[param.name || '']) {
                setRequiredNodesNotSelected([...requiredNodesNotSelected, param.name!]);
            }
        });

        if (!isScriptRequiredEnabled) setIsScriptRequiredEnabled(true);

        if (form) {
            form.validateFields()
                .then((formValues) => {
                    if (
                        !requiredFilesNotLoaded.length &&
                        !requiredNodesNotSelected.length &&
                        scriptSchedulerData.script &&
                        scriptSchedulerData.existingParams &&
                        scriptSchedulerData.parsedParams &&
                        !hasSchedulerRepeatError
                    ) {
                        form.resetFields();
                        scriptSchedulerData.parsedParams.forEach((param) => {
                            switch (param.paramType) {
                                case ParameterType.FILE:
                                    param.value = fileParams[param.name || '']?.uploadedFileName;
                                    break;
                                case ParameterType.BOOLEAN:
                                    param.value =
                                        formValues[param.name || '']?.toString().toLowerCase() === 'true'
                                            ? 'true'
                                            : 'false';
                                    break;
                                case ParameterType.DATE:
                                    const value = dayjs(formValues[param.name || ''] as string).unix();
                                    param.value = value ? value.toString() : '';
                                    break;
                                case ParameterType.NODE:
                                    const nodeId = nodeParams[param.name || '']?.nodeId;
                                    param.value = nodeId ? JSON.stringify(nodeId) : '';
                                    break;
                                case ParameterType.QUERY_SELECT:
                                    param.value =
                                        (formValues[param.name || ''] as AttributeValueQuery[] | undefined)?.[0]?.id ||
                                        '';
                                    break;
                                case ParameterType.QUERY_MULTI_SELECT:
                                    param.value = (formValues[param.name || ''] as AttributeValueQuery[] | undefined)
                                        ? JSON.stringify(
                                              (formValues[param.name || ''] as AttributeValueQuery[]).map(
                                                  (queryAttributeValue) => queryAttributeValue.id,
                                              ),
                                          )
                                        : '';
                                    break;
                                default:
                                    param.value = (formValues[param.name || ''] || '').toString();
                                    break;
                            }
                        });

                        if (props.isEdit) {
                            if (!props?.id) {
                                console.error('Error update script task id is empty');
                                return;
                            }
                            dispatch(
                                scriptEditScheduleSubmit({
                                    task: {
                                        id: props.id,
                                        scriptId: scriptSchedulerData.script.nodeId.id,
                                        login: props.login,
                                        timeSettings,
                                        disable,
                                        parameters: [
                                            ...scriptSchedulerData.existingParams,
                                            ...scriptSchedulerData.parsedParams,
                                        ],
                                    },
                                    serverId: props.serverId,
                                }),
                            );
                        } else {
                            dispatch(
                                scriptSchedule(
                                    scriptSchedulerData.script.nodeId,
                                    [...scriptSchedulerData.existingParams, ...scriptSchedulerData.parsedParams],
                                    timeSettings,
                                    disable,
                                ),
                            );
                        }

                        dispatch(closeDialog(DialogType.SCHEDULE_SCRIPT_DIALOG));
                    }
                })
                .catch(() => undefined);
        }
    };
    const onOpenSelectScript = () => {
        dispatch(
            selectScriptToScheduleDialogInit({
                serverId: props.serverId,
                nodeId: props.nodeId,
                nodesIdsList: props.nodesIdsList,
                modelId: props.modelId,
                element: props.element,
                elementsIdsList: props.elementsIdsList,
            }),
        );
    };

    const onUploadFile = (serverId: string, paramName: string) => {
        dispatch(openDialog(DialogType.SCRIPT_UPLOAD_PARAM_FILE_DIALOG, { serverId, paramName }));
    };
    const onAddNode = (paramName) => {
        dispatch(
            openDialog(DialogType.TREE_ITEM_SELECT_DIALOG, {
                serverId: scriptSchedulerData.script?.nodeId.serverId,
                onSubmit: (nodeId) => dispatch(scriptExecuteDialogAddNodeId(paramName, nodeId)),
                disableContextMenu: true,
            }),
        );
    };
    const onDeleteNode = (paramName) => dispatch(scriptExecuteDialogDeleteNodeId(paramName));

    const onChangeDisable = (e: CheckboxChangeEvent) => {
        setDisable(!e.target.checked);
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(scriptExecuteMessages.scriptFormDeclineButton),
                    dataTest: 'schedule-script-dialog_cancel-button',
                },
                {
                    key: 'ok',
                    onClick: onSubmit,
                    value: props.isEdit
                        ? intl.formatMessage(scriptExecuteMessages.save)
                        : intl.formatMessage(scriptExecuteMessages.scriptFormConfirmButtonPlan),
                    visualStyle: 'primary',
                    dataTest: 'schedule-script-dialog_schedule-button',
                },
            ]}
        />
    );

    return (
        <Dialog
            title={intl.formatMessage(scriptExecuteMessages.scheduleScriptTitle)}
            open
            className={theme.dialog}
            maskClosable={false}
            onCancel={onClose}
            width="655px"
            footer={footer}
        >
            <Form.Item className={theme.field} required>
                <div className={theme.scriptInput}>
                    <div className={theme.scriptInputLabel}>{intl.formatMessage(messages.script)}</div>
                    <Input
                        className={theme.node}
                        disabled
                        type="text"
                        data-test="schedule-script-dialog_input-script"
                        value={scriptSchedulerData.script?.name || ''}
                    />
                    <Button
                        className={theme.nodeButton}
                        icon={<EditOutlined />}
                        onClick={onOpenSelectScript}
                        data-test="schedule-script-dialog_edit-script-button"
                    />
                </div>
                {isScriptRequiredEnabled && !scriptSchedulerData.script ? (
                    <div className={theme.unselectedScriptText} data-test="schedule-script-dialog_error-tip">
                        {intl.formatMessage(messages.selectScript)}
                    </div>
                ) : null}
            </Form.Item>

            <div className={theme.scriptDisableContainer}>
                <Checkbox
                    checked={!disable}
                    onChange={onChangeDisable}
                    data-test="schedule-script-dialog_enable-task-checkbox"
                >
                    <span>{intl.formatMessage(messages.enableTask)}</span>
                </Checkbox>
            </div>

            <DatetimeSchedulerSettings settings={timeSettings} setSettings={setTimeSettings} />

            {scriptSchedulerData.script ? (
                <ScriptExecuteForm
                    existingParams={scriptSchedulerData.existingParams || []}
                    parsedParams={scriptSchedulerData.parsedParams || []}
                    scriptId={scriptSchedulerData.script.nodeId}
                    onUploadFile={onUploadFile}
                    onAddNode={onAddNode}
                    fileParams={fileParams}
                    nodeParams={nodeParams}
                    onDeleteNode={onDeleteNode}
                    form={form}
                    onPreviousStep={() => {}}
                />
            ) : null}
        </Dialog>
    );
};
